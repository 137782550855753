/* JoinList.css */
.join-form {
    max-width: 100%;
    margin: 0 auto;
  }
  
  .input-group {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .email-input {
    flex: 1;
    /* max-width: 400px; */
    padding: 12px 16px;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    background-color: white;
    font-family: 'Lato', sans-serif;  /* Ajoute la police Lato */

  }
  
  .submit-button {
    padding: 12px 24px;
    background-color: #000000;  /* Optionnel : adapte la couleur du bouton */
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;  /* Ajoute la police Lato */
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap;
  }
  
  .submit-button:hover {
    background-color: #264567;  /* Version plus foncée pour le hover */
  }
  
  .submit-button:disabled {
    background-color: #718096;
    cursor: not-allowed;
  }
  
  .message {
    margin-top: 1rem;
    padding: 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 768px) {
    .input-group {
      flex-direction: column;
    }
  
    .submit-button {
      width: 100%;
    }
  }