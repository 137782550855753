/* App.css */
/* App.css */
.App {
  min-height: 100vh;
  background-color: #000000;  
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.title-container {
  font-size: 3rem;
  display: flex;
  align-items: center;
}

.stacked-text {
  position: relative;
  top: -58px;
  display: inline-block;
}
.regular-text {
  color: white;
}

.stacked-text span {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

/* Premier texte (arrière-plan) */
.text-1 {
  color: #F24405;
  top: 8px;
  z-index: 5;
}

/* Deuxième texte */
.text-2 {
  color: #FA7F08;
  top: 6px;
  z-index: 4;
}

/* Troisième texte */
.text-3 {
  color: #9EF8EE;
  top: 4px;
  z-index: 3;
}

/* Quatrième texte */
.text-4 {
  color: #22BABB;
  top: 2px;
  z-index: 2;
}

/* Cinquième texte (premier plan) */
.text-5 {
  color: #348888;
  top: 0px;
  z-index: 1;
}

.colored-text {
  color: #282828;
}
.content-wrapper {
  max-width: 1000px;
  width: 80%;
  padding: 40px;
  background-color: #282828;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; 
}

.text-content {
  flex: 1;
  max-width: 500px;
  text-align: center;
  position: relative;
  z-index: 2;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: center;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  text-align: left;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  justify-content: left;
  margin-top: 2rem;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon {
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(1);
}

.social-link:hover {
  opacity: 0.8;
}

.illustration {
  flex: 1;
  max-width: 500px;
  position: absolute; 
  right: -50px; 
  top: -80px; 
  z-index: 1; 
}

.illustration img {
  height: auto;
}
@media (max-width: 1162px) {
  .text-content {
    max-width: 300px;
  }

  .stacked-text{
    top: -92px;
    right: -45.6px;

  }

}


@media (max-width: 975px) {
  .App{
    padding: 0;
  }
  .content-wrapper {
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }
  .illustration {
    position: inherit;
    right: 0px;
    top: 0px;
    width: 50%;
  }

  .illustration img {
    width: 100%;
  }
  .text-content {
    order: 1;
  }

  h1 {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
    text-align: center;
  }
}